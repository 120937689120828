import * as React from 'react';
import Box from '@mui/material/Box';
import { Card, Typography } from '@mui/material';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DoneIcon from '@mui/icons-material/Done';
import SimulationTooltip from './SimulationTooltip.jsx';

export default function SceneCard(props) {

    const materialBackground = {
        height: "180px", width: "100%",
        backgroundImage: "url("+ props.thumbnail +")",
        backgroundSize: "120%", backgroundPosition: "center"
    }

    const tileElements = {
        Succeeded: {
            icon: "",
            tooltipText: undefined,
            color: "#00FF00",
            disableClick: false
        },
        Running: {
            icon: <HourglassTopIcon fontSize="48px"/>,
            tooltipText: "The simulation is still running. Progress reporting or cancelling of a running simulation is not implemented yet.",
            color: "#888888",
            disableClick: true
        },
        Failed: {
            icon: <ReportProblemIcon fontSize="48px"/>,
            tooltipText: "The simulation failed. Reporting of a failure reason is not implemented yet.",
            color: "#FF0000",
            disableClick: true
        },
    }

    const status = props.status ? tileElements[props.status] : undefined;
    const disableClick = status ? (status.disableClick) : false;

    return (
        <Card 
            sx={{
                position: "relative",
                "&:hover": disableClick ? undefined : {cursor: "pointer"}
            }} 
            onClick={disableClick ? undefined : () => props.onSceneClicked(props.sceneId)}
        >
            <Box sx={materialBackground}/>
            <Box sx={{
                    color: "#FFFFFF",
                    position: "absolute", top: 0.0, width: 1.0, height: 1.0, transition: "opacity 0.5s",
                    display: "block", alignItems: "top",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.7)", transition: "background-color 1s" },
                }}
            >
                <Box sx={{width: "100%", height: "20%", backgroundColor: "rgba(0, 0, 0, 0.3)", padding: "0.3em"}}>
                    <Typography variant="h7">{props.sceneName}</Typography>
                </Box>
                {status &&
                    <SimulationTooltip title={status.tooltipText}>
                        <Box sx={{
                            width: "100%", height: "80%", 
                            display: "flex", alignItems: "center", justifyContent: "center",
                            color: status.color
                        }}>
                            <Typography variant="h3">{status.icon}</Typography>
                        </Box>
                    </SimulationTooltip>
                }
            </Box>

        </Card>
    );
}