
import React, {useContext, useEffect, useState, useRef} from "react";
import { Box, CircularProgress, Grid } from "@mui/material";

import SceneCard from './SceneCard.jsx';

export default function SceneList(props) {

    const portalItems = props.portalItems;

    return (
        <Box>
            {!portalItems && <CircularProgress/>}
            <Grid container spacing={2} sx={{marginTop: 1}}>
                {portalItems &&  portalItems.map(
                    item => 
                        <Grid item xs={12} sm={6} key={item.id}>
                            <SceneCard 
                                key={item.id}
                                sceneName={item.title} sceneId={item.id}
                                thumbnail={item.thumbnailUrlOverride?item.thumbnailUrlOverride:item.thumbnailUrl}
                                onSceneClicked={() => props.onSceneClicked(item.id)}
                                onPreviewClicked={() => props.onPreviewClicked(item.itemPageUrl)}
                                status={item.simulationStatus}
                            />
                        </Grid>
                    )
                }
            </Grid>
        </Box>
    );
}
