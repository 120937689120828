
import React, { useEffect, useContext } from "react";
import { useImmerReducer } from 'use-immer';

import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Stack, TextField, Typography } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Coordinates from './../general/Coordinates.js';
import { ESRIContext } from '../general/ESRIConnection.js';
import SimulationAPI from './../general/SimulationAPI.js';
import { SimulationCreatorStepper, SimulationCreatorStepperItem } from './SimulationCreatorStepper.jsx';
import SceneList from './SceneList.jsx';
import SimulationTooltip from './SimulationTooltip.jsx';


export default function SimulationCreator(props) {

    const esriConnection = useContext(ESRIContext);
    const [state, dispatch] = useImmerReducer(stateReducer, initialState);

    useEffect(
        () => {
            async function start() {
                if (state.simulationStartState == SimulationStartState.startRequested) {
                    const api = new SimulationAPI(esriConnection);
                    const response = await api.startWindSimulation(state.config, props.extentBox);
                    if (response.ok) {
                        dispatch({ type: 'startOk' });
                    }
                    else {
                        const errorJson = await api.getErrorJson(response);
                        const failReason = String(errorJson.status) + ":" + errorJson.title + "<br>" + errorJson.type;
                        dispatch({ type: 'startFailed', reason: failReason })
                    }
                }
            };
            start();
        },
        [state.simulationStartState]
    );

    useEffect(
        () => {
            async function setScene() {
                if (state.inputSceneNeedsReload) {
                    const api = new SimulationAPI(esriConnection);
                    const objectsUrls = await api.getObjectsLayerURLs(state.config.inputScene);
                    // TODO (MB): If the objectUrls is not valid, nothing happens at all and the user must 
                    // click a scene again, without any notification. Obviously this is not good.
                    if(objectsUrls){
                        // TODO (MB): Not sure how to deal with such subsequent events
                        props.onSceneSelected(state.config.inputScene);
                        dispatch({ type: 'setConfig', name: 'inputObjectsUrl', value: objectsUrls.input });
                        dispatch({ type: 'setConfig', name: 'outputObjectsUrl', value: objectsUrls.output });
                        dispatch({ type: 'inputSceneChangeDone' });
                    }
                }
            };
            setScene();
        },
        [state.inputSceneNeedsReload, state.inputSceneSeqnum]
    );

    useEffect(() => {
        async function loadPortalItems() {
            const portal = esriConnection.getPortal();
            await portal.load();

            const queryParams = {
                filter: "type:\"Web Scene\" AND tags:sim-input-scene",
                sortField: "title",
                sortOrder: "asc",
                num: 100 // 100 is max allowed!!!
            };

            const portalItems = await portal.queryItems(queryParams);
            if (portalItems.results.length === 100) {
                console.log("Got exactly 100 portal items. Likely there are more.");
            }
            for(const portalItem of portalItems.results){
                // For some reasons, the JS API returns a *.jpeg, while AGOL mostly creates a *.jpg.
                // Using the direct REST call seems to return the correct suffix.
                const tokenSuffix = "?token=" + esriConnection.accessToken
                const itemUrl = portalItem.itemUrl;
                const response = await fetch(itemUrl + tokenSuffix + "&f=json", { method: 'GET' });
                const responseJson = await response.json();
                const thumbnail = responseJson["thumbnail"];
                portalItem.thumbnailUrlOverride = itemUrl + "/info/" + thumbnail + tokenSuffix;
            }

            dispatch({ type: "portalItemsLoaded", items: portalItems.results });
        }
        if (!state.portalItems) {
            loadPortalItems();
        }
    }, [state.portalItems]);

    useEffect(
        () => {
            if (state.step == SimulationCreatorSteps.STEP_EXTENT) { props.onExtentDrawingEntered(); }
            else { props.onExtentDrawingLeaved(); }
        },
        [state.step]
    );

    function isNextEnabled(){
        if(state.step == SimulationCreatorSteps.STEP_SCENE){return state.config.inputScene;}
        if(state.step == SimulationCreatorSteps.STEP_EXTENT){return extentDrawn; }
        return true;
    }

    function isBackEnabled(){
        if(state.step == SimulationCreatorSteps.STEP_NAME){ return false; }
        return true;
    }

    function makeDispatch(action) {
        return (arg) => { dispatch({ type: action, arg: arg }); }
    }

    function makeConfigSetter(name, extractFct) {
        if (!extractFct) { extractFct = (v) => v; }
        return (arg) => {
            dispatch({ type: "setConfig", name: name, value: extractFct(arg) });
        }
    }

    function makeConfigSetterFromTextfield(name) {
        return (arg) => {
            dispatch({ type: "setConfig", name: name, value: arg.target.value });
        }
    }  

    function makeConfigSetterFromNumeric(name) {
        return (arg) => {
            dispatch({ type: "setNumericConfig", name: name, value: arg.target.value });
        }
    } 

    const extentSize = Coordinates.get_bbox_size(Coordinates.degree_to_cartesian(props.extentBox, props.extentBox[0]));
    const extentDrawn = (extentSize[0] > 0.1);
    const extentString = extentSize[0].toFixed(1) + "m x " + extentSize[1].toFixed(1) + "m";
    const computationTime = ((extentSize[0] * extentSize[1]) * 2.0 / 10000).toFixed(0);

    return (
        <SimulationCreatorStepper
            step={state.step}
            onNext={isNextEnabled() ? makeDispatch('nextStep'): undefined}
            onBack={isBackEnabled() ? makeDispatch('previousStep') : undefined}
        >
            <SimulationCreatorStepperItem heading="Enter simulation name">
                <SimulationTooltip title="The name of the resulting webscene. A unique name is recommended.">
                <TextField
                    label="Simulation Name" variant="outlined"
                    onChange={makeConfigSetterFromTextfield('name')}
                    value={state.config.name}
                    sx={{width: "100%"}}
                />
                </SimulationTooltip>
            </SimulationCreatorStepperItem>
            <SimulationCreatorStepperItem heading="Select scene">
                <Typography variant="body">Please select an input scene for the simulation:</Typography>
                <SceneList
                    portalItems={state.portalItems}
                    onSceneClicked={(itemId) => { dispatch({ type: 'setInputScene', itemId: itemId }) }}
                    onPreviewClicked={(url) => console.log("previewClicked")}
                />
            </SimulationCreatorStepperItem>
            <SimulationCreatorStepperItem heading="Select extent">
                {!extentDrawn && <Typography variant="body">Please draw the simulation extent into the scene.</Typography>}
                {extentDrawn &&
                <Stack>
                    <Box>
                    <Typography variant="body">
                        Size of the currently drawn extent: {extentString}
                    </Typography>
                    </Box>
                    <Box>
                    <Typography variant="body">
                        Computation time estimation: {computationTime} minutes.
                    </Typography>
                    </Box>
                    <Box>
                    <Typography variant="body">
                        To change the extent, just draw a new one.
                    </Typography>
                    </Box>
                </Stack>
                }
            </SimulationCreatorStepperItem>
            <SimulationCreatorStepperItem heading="Configure simulation">
                <Accordion defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Wind Configuration</AccordionSummary>
                    <AccordionDetails>
                        <Box>
                            <SimulationTooltip title="Wind direction in degree. 0 is from the north, 90 is from the east. Allowed values from 0 - 360, without the ° sign.">
                                <TextField label="Meteorological Wind Direction (°)" value={state.config.meteorological_direction} onChange={makeConfigSetterFromNumeric('meteorological_direction')}  sx={{width: "100%", marginTop: "1rem"}}/>
                            </SimulationTooltip>
                            <SimulationTooltip title="Wind velocity in m/s. Recommended values are from 1 to 15.">
                                <TextField label="Wind Velocity (m/s)" value={state.config.velocity} onChange={makeConfigSetterFromNumeric('velocity')}  sx={{width: "100%", marginTop: "1rem"}}/>
                            </SimulationTooltip>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Solver Parameters</AccordionSummary>
                    <AccordionDetails>
                        <SimulationTooltip title="Debug settings. Do not touch unless you know exactly what you do.">
                            <Box>
                                <TextField label="Terrain Resolution (m)" value={state.config.terrain_resolution} onChange={makeConfigSetterFromNumeric('terrain_resolution')} sx={{width: "100%", marginTop: "1rem"}}/>
                                <TextField label="Objects Resolution (m)" value={state.config.objects_resolution} onChange={makeConfigSetterFromNumeric('objects_resolution')} sx={{width: "100%", marginTop: "1rem"}}/>
                                <TextField label="Measurement Offset (m)" value={state.config.measurement_offset} onChange={makeConfigSetterFromNumeric('measurement_offset')} sx={{width: "100%", marginTop: "1rem"}}/>
                            </Box>
                        </SimulationTooltip>
                    </AccordionDetails>
                </Accordion>

                <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>Debug Parameters</AccordionSummary>
                    <AccordionDetails>
                        <SimulationTooltip title="Debug settings. Do not touch unless you know exactly what you do.">
                            <Box>
                                <TextField label="Input Objects URL" value={state.config.inputObjectsUrl} onChange={makeConfigSetterFromTextfield('inputObjectsUrl')} sx={{width: "100%", marginTop: "1rem"}}/>
                                <TextField label="Output Objects URL" value={state.config.outputObjectsUrl} onChange={makeConfigSetterFromTextfield('outputObjectsUrl')} sx={{width: "100%", marginTop: "1rem"}}/>
                                <TextField label="Simulation ID Field Name" value={state.config.simulationIdField} onChange={makeConfigSetterFromTextfield('simulationIdField')} sx={{width: "100%", marginTop: "1rem"}}/>
                            </Box>
                        </SimulationTooltip>
                    </AccordionDetails>
                </Accordion>

            </SimulationCreatorStepperItem>
            <SimulationCreatorStepperItem heading="Review and start">
                <Box>
                {state.simulationStartState == SimulationStartState.configuring &&
                    <Typography variant="body">Click "Start" to start the simulation.</Typography>
                }
                {state.simulationStartState == SimulationStartState.startRequested &&
                    <Typography variant="body">Simulation is starting...</Typography>
                }
                {state.simulationStartState == SimulationStartState.startOk &&
                    <Typography variant="body">Started successfully!</Typography>
                }
                {state.simulationStartState == SimulationStartState.startFailed &&
                    <Stack>
                        <Box><Typography variant="body">Simulation start failed:</Typography></Box>
                        <Box><Typography variant="body">{state.failReason}</Typography></Box>
                    </Stack>
                }
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        onClick={() => dispatch({ type: 'start' })}
                        disabled={
                            (
                                (state.simulationStartState == SimulationStartState.startOk)
                                ||
                                (state.simulationStartState == SimulationStartState.startRequested)
                            )
                                ?
                                true : undefined
                        }
                    >
                        Start Simulation!
                    </Button>
                    </Box>
            </SimulationCreatorStepperItem>
        </SimulationCreatorStepper>
    );
}

const SimulationCreatorSteps = {
    STEP_NAME: 0,
    STEP_SCENE: 1,
    STEP_EXTENT: 2,
    STEP_PARAMS: 3,
    STEP_CONFIRM: 4,
    N_STEPS: 5
}

const SimulationStartState = {
    configuring: "configuring",
    startRequested: "startRequested",
    startOk: "startOk",
    startFailed: "startFailed"
}

const initialState = {
    step: 0,
    simulationStartState: SimulationStartState.configuring,
    failReason: "",
    inputSceneNeedsReload: false,
    inputSceneSeqnum: 0, // To always trigger the effect.
    portalItems: undefined,
    config: {
        // TODO (MB): Not nice that we handle those as strings here,
        // but else it's difficult to enter a dot.
        velocity: "5",
        meteorological_direction: "45",
        terrain_resolution: "3",
        objects_resolution: "1",
        measurement_offset: "2",
        name: "Wind Simulation",
        inputScene: undefined,
        inputObjectsUrl: "",
        outputObjectsUrl: "",
        simulationIdField: "name"
    }
};

function stateReducer(draft, action) {
    console.log(action);
    switch (action.type) {
        case 'nextStep': {
            draft.step = Math.min(draft.step + 1, SimulationCreatorSteps.N_STEPS - 1);
            return;
        }
        case 'previousStep': {
            draft.step = Math.max(draft.step - 1, 0);
            return;
        }
        case 'portalItemsLoaded': {
            draft.portalItems = action.items;
        }
        case 'setConfig': {
            draft.config[action.name] = action.value;
            draft.simulationStartState = SimulationStartState.configuring;
            return;
        }
        case 'setNumericConfig': {
            // You need to keep the string and can't convert to Number already here, 
            // because the Number("5.") would convert to 5 in the textfield, so the
            // dot is eaten away.
            const filteredValue = action.value.replace(/[^0-9.]/g, "");
            draft.config[action.name] = filteredValue;
            draft.simulationStartState = SimulationStartState.configuring;
            return;
        }
        case 'setInputScene': {
            draft.config.inputScene = action.itemId;
            draft.inputSceneNeedsReload = true;
            draft.inputSceneSeqnum += 1;
            return;
        }
        case 'inputSceneChangeDone': {
            draft.simulationStartState = SimulationStartState.configuring;
            draft.inputSceneNeedsReload = false;
            draft.step += 1;
            return;
        }
        case 'start': {
            draft.simulationStartState = SimulationStartState.startRequested;
            return;
        }
        case 'startFailed': {
            draft.simulationStartState = SimulationStartState.startFailed;
            draft.failReason = action.reason;
            return;
        }
        case 'startOk': {
            draft.simulationStartState = SimulationStartState.startOk;
            return;
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}