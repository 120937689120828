const Coordinates = {
    m_per_degree_north: 110.95 * 1000,
    radius_equator: 6378 * 1000,
  
    m_per_degree_east(lat) {
        return Math.cos((lat * Math.PI) / 180) * this.radius_equator * Math.PI / 180;
    },
  
    degree_to_cartesian(degree_global, origin_degree_global) {
        const north_degree_local = degree_global.map((degrees) => degrees[1] - origin_degree_global[1]);
        const east_degree_local = degree_global.map((degrees) => degrees[0] - origin_degree_global[0]);
        const east_cartesian = east_degree_local.map((degree) => this.m_per_degree_east(origin_degree_global[1]) * degree);
        const north_cartesian = north_degree_local.map((degree) => this.m_per_degree_north * degree);
        const cartesian = east_cartesian.map((value, index) => [north_cartesian[index], value]);
        return cartesian;
    },
  
    cartesian_to_degree(cartesian_local, origin_degree_global) {
        const north_degree_local = cartesian_local.map((cartesian) => cartesian[0] / this.m_per_degree_north);
        const east_degree_local = cartesian_local.map((cartesian) => cartesian[1] / this.m_per_degree_east(origin_degree_global[1]));
        const degree_local = east_degree_local.map((value, index) => [value, north_degree_local[index]]);
        const degree_global = degree_local.map((degrees) => [degrees[0] + origin_degree_global[0], degrees[1] + origin_degree_global[1]]);
        return degree_global;
    },
  
    square_around_center_degree(degree_center, edge_length) {
        const half_edge_length = edge_length / 2;
        const bbox_cartesian = [[-half_edge_length, -half_edge_length], [half_edge_length, half_edge_length]];
        const bbox_degree = this.cartesian_to_degree(bbox_cartesian, degree_center);
        return bbox_degree;
    },

    get_bbox_size(bbox_cartesian){
        return [
            bbox_cartesian[1][0] - bbox_cartesian[0][0],
            bbox_cartesian[1][1] - bbox_cartesian[0][1],
        ]
    }
  };
  
  export default Coordinates;