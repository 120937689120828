// Error reporting see: https://datatracker.ietf.org/doc/html/draft-ietf-appsawg-http-problem-00

export default class APIConnection {

    constructor(apiBasePath){
        this.apiBasePath = apiBasePath;
    }

    async request(path, requestOptions){
        return await fetchWithTimeout(this.apiBasePath + path, requestOptions);
    }

    async jsonBodyRequest(path, bodyJson, requestOptions){
        return await fetchWithTimeout(
            this.apiBasePath + path,
            {
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(bodyJson),
                ...requestOptions
            }
        );
    }

    async getBodyJson(response){
        try{
            return await response.json();
        }
        catch(error){
            return null;
        }        
    }

    async getErrorJson(response){
        const bodyJson = await this.getBodyJson(response);
        if(bodyJson === null){
            return {
                status: response.status, 
                title: response.statusText, 
                type: "Received this error without further information from the server.", 
                detail: "about:blank"
            };
        }
        else{
            return bodyJson;
        }
    }
   
};


async function fetchWithTimeout(path, options, timeout=3000){
    try{
        const abortController = new AbortController();
        const timeoutId = setTimeout(() => abortController.abort(), timeout);
        const optionsWithDefaults = {method: "GET", signal: abortController.signal, ...options};
        const response = await fetch(path, optionsWithDefaults);
        clearTimeout(timeoutId);
        return response;
    }
    catch(error){
        if((error instanceof DOMException) && (error.name === "AbortError")){
            return Response.json(
                {
                    status: 499, 
                    title: "Client-Side Error: Timeout Reaching Server", 
                    type: "Timeout (on client side) when connecting to server. Is the service up and reachable?", 
                    detail: "about:blank"
                },
                {ok: false, status: 499, statusText: 'Timeout From Client Side'}
            )
        }
        else{
            return Response.json(
                {
                    status: 499, 
                    title: "Client-Side Error: Unknown", 
                    type: "An unknown error was caught on the client side when trying to connect to the server.", 
                    detail: "about:blank"
                },
                {ok: false, status: 499, statusText: 'Error On Client Side'}
            )
        }
    }
}