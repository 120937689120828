import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

export function SimulationCreatorStepper(props) {

    const activeStep = props.step;
    const maxSteps = props.children.length;

    return (
        <Box sx={{height: "100%", display: "flex", flexDirection: "column"}}>
            <Box sx={{ width: '100%', p: 2, flex: "1 1 auto", overflow: "auto"}}>
                {props.children[activeStep]}
            </Box>
            <MobileStepper
                sx={{flex: "0 0 content"}}
                variant="text"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                nextButton={
                    <Button size="small" onClick={props.onNext} disabled={props.onNext?undefined:true}>
                        {activeStep != maxSteps - 1 && <><KeyboardArrowRight />Next</>}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={props.onBack} disabled={props.onBack?undefined:true}>
                        <KeyboardArrowLeft />Back
                    </Button>
                }
            />
        </Box>
    );
}

export function SimulationCreatorStepperItem(props) {
    return (<Box>{props.children}</Box>)
}