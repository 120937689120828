
import React, {useContext, useEffect, useState} from "react";

import { Box } from '@mui/material';

import {ESRIContext} from '../general/ESRIConnection.js';
import SceneList from './SceneList.jsx';

export default function SimulationList(props) {

    const [portalItems, setPortalItems] = useState(undefined);

    const esriConnection = useContext(ESRIContext);

    useEffect(() => {
        async function loadPortalItems(){
            const portal = esriConnection.getPortal();
            await portal.load();
            
            const queryParams = {
                filter: "tags:sim-result-scene",
                sortField: "created",
                sortOrder: "desc",
                num: 500
              };

            const portalItems = await portal.queryItems(queryParams);
            for(const portalItem of portalItems.results){
                portalItem.simulationStatus = getStatus(portalItem);
                portalItem.thumbnailUrlOverride = esriConnection.getWebsceneUrl(portalItem.id) + "/resources/terrain.png?token=" + esriConnection.accessToken ;
            }
            setPortalItems(portalItems.results);
        }
        loadPortalItems();
        const interval = setInterval(loadPortalItems, 3000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    function getStatus(item){
        if(item.tags.includes("Succeeded")){
            return "Succeeded";
        }
        else if(item.tags.includes("Failed")){
            return "Failed";
        }
        else{
            return "Running";
        }
    }

    return (
        <Box sx={{margin: "1rem"}}>
            <SceneList portalItems={portalItems} onSceneClicked={props.onSceneClicked}/>
        </Box>
    );
}
