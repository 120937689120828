
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import IdentityManager from "@arcgis/core/identity/IdentityManager";
import Portal from "@arcgis/core/portal/Portal";

import {createContext} from "react";

const portals = {
    "https://zrh.mapsdevext.arcgis.com": {
        appId: "12OQq9xLTWlpT1xE",
        terrainUrl: "https://elevationdev.arcgis.com/arcgis/rest/services/WorldElevation/Terrain/ImageServer"
    },
    "https://www.arcgis.com": {
        appId: "XJxHGqgR3AasagkG",
        terrainUrl: "https://elevation.arcgis.com/arcgis/rest/services/WorldElevation/Terrain/ImageServer"
    }
}

class ESRIConnection{

    constructor(){
        this.apiBasePath = "/simapi";
        this.portalUrl = "";
        this.terrainUrl = "";
        this.appId = ""
        this.userId = null;
        this.accessToken = null;
        this.refreshToken = null;
        this.signedIn = false;
    }

    async checkSignIn(){
        const sessionOauthString = sessionStorage.getItem("esriJSAPIOAuth");
        const sessionOauth = JSON.parse(sessionOauthString);
        if(sessionOauth != null){
            const sessionOauthPortals = Object.keys(sessionOauth["/"]);
            if(sessionOauthPortals.length > 0){
                const portalUrl = sessionOauthPortals[0];
                this.setPortal(portalUrl);
            }
        }
        let signedIn = false;
        try{
            signedIn = await IdentityManager.checkSignInStatus(this.portalUrl);
        }
        catch(error){
            signedIn = false;
        }
        if(signedIn){
            const credentials = await IdentityManager.getCredential(this.portalUrl + "/sharing");
            this.userId = credentials.userId;
            this.accessToken = credentials.token;
            // TODO (MB): This is a non-public property! Dangerous!
            this.refreshToken = credentials._oAuthCred.refreshToken;
            this.signedIn = true;
        }
    }

    signIn(portalUrl){
        // This just re-directs to the Sign-In page if not signed in.
        // So no need to handle any results.
        this.setPortal(portalUrl);
        IdentityManager.getCredential(portalUrl + "/sharing");
    }

    signOut(){
        IdentityManager.destroyCredentials();
        window.location.reload();       
    }

    getPortal(){
        return new Portal({
            url: this.portalUrl
          });
    }

    setPortal(portalUrl){
        const portal = portals[portalUrl];
        this.portalUrl = portalUrl;
        this.terrainUrl = portal.terrainUrl;
        this.appId = portal.appId;
        this.oAuthInfo = new OAuthInfo({
            appId: this.appId,
            flowType: "auto",
            popup: false,
            portalUrl: this.portalUrl,
            expiration: 36 * 60
        });
        IdentityManager.registerOAuthInfos([this.oAuthInfo]);
    }

    getWebsceneUrl(websceneId){
        return this.portalUrl + "/sharing/rest/content/items/" + websceneId;
    }  

    getWebsceneAdminUrl(websceneId){
        return this.portalUrl + "/sharing/rest/content/users/" + this.esriConnection.userId + "/items/" + websceneId;
    }

}

export const ESRIContext = createContext(null);
export const esriConnection = new ESRIConnection();