import './App.css';

import {useEffect, useRef} from "react";
import { useImmerReducer } from 'use-immer';

import CssBaseline from '@mui/material/CssBaseline';
import {Backdrop, Box, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem}  from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PlaylistPlay from '@mui/icons-material/PlaylistPlay';
import PlaylistAdd from '@mui/icons-material/PlaylistAdd';

import LoginPage from "./components/LoginPage.jsx"
import SimulationSceneViewer from './components/SimulationSceneViewer.jsx';
import UIContainer from './components/UIContainer.jsx'
import SimulationList from './components/SimulationList.jsx'
import SimulationCreator from './components/SimulationCreator.jsx'
import {ESRIContext, esriConnection} from './general/ESRIConnection.js';

export default function App() {

    const [state, dispatch] = useImmerReducer(appStateReducer, initialState);

    const menuAnchorRef = useRef();

    useEffect(() => {
        async function checkSignIn(){
            await esriConnection.checkSignIn()
            if(esriConnection.signedIn){
                console.log("Refresh token: " + esriConnection.refreshToken);
                dispatch({type: 'loggedIn'});
            }
        };
        checkSignIn();
    }, []);

    function makeDispatch(action){
        return (arg) => {dispatch({type: action, arg: arg}); }
    }

    return (
        <ESRIContext.Provider value={esriConnection}>
            <CssBaseline/>
            {!state.signedIn && <LoginPage/>}
            {state.signedIn &&
                <>
                <Box sx={{
                    position: "absolute", height: "100%", width: "100%",
                    display: "flex", 
                    alignItems: "start", justifyContent: "center"
                }}>
                    <Box sx={{position: "absolute", height: "100%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                        {state.websceneId &&
                            <SimulationSceneViewer 
                                websceneId={state.websceneId} 
                                websceneType={state.websceneType}
                                extentDrawing={state.extentDrawing}
                                onExtentDrawed={makeDispatch('extentDrawed')}
                            />
                        }
                        {!state.websceneId &&
                            <Box sx={{width: "30%"}}>
                                <Box>No scene loaded yet.</Box>
                                <Box>Please use the <b>menu in the top-left corner</b> to create a new simulation or view an existing simulation.</Box>
                            </Box>
                        }
                    </Box>                   
                    {
                        state.uiContainer == "simulationList" && 
                        <UIContainer onClose={makeDispatch('simulationListClosed')} title="Open Simulation Result">
                            <SimulationList onSceneClicked={makeDispatch('simulationSelected')}/>
                        </UIContainer>
                    }
                    {
                        state.uiContainer == "newSimulation" && 
                        <UIContainer onClose={makeDispatch('newSimulationClosed')} height={state.creatorContainerHeight}  title="Start New Simulation">
                            <SimulationCreator
                                extentBox={state.extentBox}
                                onExtentDrawingEntered={makeDispatch('extentDrawingEntered')}
                                onExtentDrawingLeaved={makeDispatch('extentDrawingLeaved')}
                                onSceneSelected={makeDispatch('inputSceneSelected')}
                            />
                        </UIContainer>
                    }              
                </Box>
                <IconButton 
                    size="large" edge="start" color="inherit" sx={{ m: 2 }}
                    onClick={makeDispatch('menuOpened')}
                    ref={menuAnchorRef}
                >
                    <MenuIcon/>
                </IconButton>
                <Menu
                    id="basic-menu"
                    open={state.menuOpen}
                    onClose={makeDispatch('menuClosed')}
                    anchorEl={menuAnchorRef.current}
                >
                    <MenuItem onClick={makeDispatch('simulationListClicked')}>
                        <ListItemIcon><PlaylistPlay fontSize="small" /></ListItemIcon>
                        <ListItemText>Open Simulation</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={makeDispatch('newSimulationClicked')}>
                        <ListItemIcon><PlaylistAdd fontSize="small" /></ListItemIcon>
                        <ListItemText>New Simulation</ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={() => esriConnection.signOut()}>
                        <ListItemIcon><LogoutIcon fontSize="small" /></ListItemIcon>
                        <ListItemText>Logout</ListItemText>
                    </MenuItem>
                </Menu>
                </>
            }
        </ESRIContext.Provider>
    );
}

const initialState = {
    signedIn: false,
    websceneId: undefined,
    websceneType: undefined,
    legendImage: undefined,
    extentDrawing: false,
    creatorHeight: undefined,
    extentBox: [[0,0],[0,0]],
    menuOpen: false,
    uiContainer: undefined
}

function appStateReducer(draft, action){
    console.log(action);
    switch(action.type){
        case 'loggedIn': { 
            draft.signedIn = true;
            return;
        }
        case 'menuClosed': {
            draft.menuOpen = false;
            return;
        }
        case 'menuOpened': {
            draft.menuOpen = true;
            return;
        }
        case 'simulationListClicked': {
            draft.menuOpen = false;
            draft.uiContainer = "simulationList";
            return;
        }
        case 'simulationListClosed': {
            draft.uiContainer = undefined;
            return;
        }
        case 'newSimulationClicked': {
            draft.menuOpen = false;
            draft.uiContainer = "newSimulation";
            return;
        }
        case 'newSimulationClosed': {
            draft.uiContainer = undefined;
            return;
        }
        case 'simulationSelected': {
            draft.websceneId = action.arg;
            draft.websceneType = "result";
            draft.uiContainer = undefined;
            return;
        }
        case 'extentDrawingEntered': {
            draft.extentBox = [[0, 0], [0, 0]];
            draft.extentDrawing = true;
            draft.creatorContainerHeight = "280px";
            return;
        }
        case 'extentDrawingLeaved': {
            draft.extentDrawing = false;
            draft.creatorContainerHeight = undefined;
            return;
        }
        case 'extentDrawed': {
            draft.extentBox = action.arg;
            return
        }
        case 'inputSceneSelected': {
            draft.websceneId = action.arg;
            draft.websceneType = "input";
            draft.legendImage = undefined;
            return;
        }
        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}

function getLegendImageUrl(websceneId){

}