import './SimulationSceneViewer.css';

import React, { useEffect, useRef, useContext, useState} from "react";

import ExtentAndRotationGeoreference from "@arcgis/core/layers/support/ExtentAndRotationGeoreference";
import Extent from "@arcgis/core/geometry/Extent";
import ImageElement from "@arcgis/core/layers/support/ImageElement";
import * as projection from "@arcgis/core/geometry/projection.js";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import MediaLayer from "@arcgis/core/layers/MediaLayer";
import SceneView from "@arcgis/core/views/SceneView";
import Sketch from "@arcgis/core/widgets/Sketch";
import LayerList from "@arcgis/core/widgets/LayerList";
import Legend from "@arcgis/core/widgets/Legend";
import WebScene from "@arcgis/core/WebScene";

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import {ESRIContext} from '../general/ESRIConnection.js';

export default function SimulationSceneViewer(props) {

    const esriConnection = useContext(ESRIContext);
    const viewDivRef = useRef(null);
    const sceneViewWrapperRef = useRef(null);

    const [ready, setReady] = useState(false);

    useEffect(() => {
        if(!sceneViewWrapperRef.current){
            sceneViewWrapperRef.current = new SceneViewWrapper(viewDivRef.current, esriConnection);
            sceneViewWrapperRef.current.onExtentDrawed = props.onExtentDrawed;
            sceneViewWrapperRef.current.onReady = (readyState) => {setReady(readyState); }
        }
    }, []);

    useEffect(() => {
        setReady(false);
        sceneViewWrapperRef.current.setScene(props.websceneId, props.websceneType);
    }, [props.websceneId, props.websceneType]);

    useEffect(() => {
        setReady(false);
        sceneViewWrapperRef.current.setDrawingState(props.extentDrawing);
    }, [props.extentDrawing]);

    const legendUrl = (
        props.websceneType == "result" ? 
            `${esriConnection.portalUrl}/sharing/rest/content/items/${props.websceneId}/resources/colormap.png?token=${esriConnection.accessToken}`
            :
            undefined
    );

    return (
        <>
            <Backdrop sx={{ color: '#fff'}} open={!ready}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div ref={viewDivRef} className="SimulationViewer-viewDiv"></div>
            
            <Box sx={{
                    position: "absolute", height: "100%", width: "100%",
                    display: "flex", 
                    alignItems: "flex-end", justifyContent: "flex-end",
                    pointerEvents: "none"
                }}
            >
                {false && 
                    <Box sx={{backgroundColor: "#FFFFFF", padding: "1rem", margin: "1rem", marginBottom: "2rem"}}>
                        <img src={legendUrl} sx={{width: "30px", height: "auto"}}/>
                    </Box>
                }
            </Box>
            
        </>
    );
}

async function getImagesForMediaLayer(websceneURL, accessToken){
    const imageDataURL = `${websceneURL}/resources/image-coords.json?f=json&token=${accessToken}`;
    const response = await fetch(imageDataURL);
    const imageDataArray = await response.json();

    if(imageDataArray.error){
        return false;
    }

    let imageElements = [];
    for(let imageData of imageDataArray){
        imageData["image"] = `${websceneURL}/resources/${imageData["image"]}?f=json&token=${accessToken}`
        imageData["georeference"] = new ExtentAndRotationGeoreference({
            extent: new Extent(imageData["georeference"])
        });
        const imageElement = new ImageElement(imageData);
        imageElements.push(imageElement);
    }
    return imageElements;
}

async function printSimulationWebsceneMetaInfo(websceneURL, accessToken){
    const url = `${websceneURL}/resources/meta.json?f=json&token=${accessToken}`;
    const response = await fetch(url);
    const jsonData = await response.json();
    console.log("Setting up webscene " + websceneURL + " with meta info: ");
    console.log(jsonData);
}

class SceneViewWrapper{

    constructor(viewDiv, esriConnection){
        this.viewDiv = viewDiv;
        this.esriConnection = esriConnection;
        this.extentDrawing = false;
        this.onExtentDrawed = (extent) => {console.log("onExtentDrawed not set");};
        this.onReady = (readyState) => {console.log("onReady not set");};

        this.websceneType = undefined;

        this.desiredWebsceneId = undefined;
        this.desiredDrawingState = false;

        this.currentWebsceneId = undefined;
        this.currentDrawingState = false;

        this.ready = false;

        console.log("Calling _matchState for the first time!");
        this._matchState();
    }

    isReady(){
        return this.ready;
    }

    setScene(websceneId, websceneType){
        this.desiredWebsceneId = websceneId;
        this.websceneType = websceneType;
    }

    setDrawingState(drawingState){
        this.desiredDrawingState = drawingState;
    }

    async _matchState(){
        let ready = true;
        if(this.desiredWebsceneId != this.currentWebsceneId){
            ready = false;
            console.log("_matchState: setup webscene")
            await this._setupWebscene();
            console.log("_matchState: setup webscene done")
        }
        if(this.desiredDrawingState != this.currentDrawingState){
            ready = false;
            console.log("_matchState: setup drawing state")
            this._setupDrawingState();
            console.log("_matchState: setup drawing state done")
        }
        this.onReady(ready);
        setTimeout(() => this._matchState(), 300);
    }

    async _setupWebscene(){
        
        const accessToken = this.esriConnection.accessToken;
        const websceneURL = `${this.esriConnection.portalUrl}/sharing/rest/content/items/${this.desiredWebsceneId}`
        printSimulationWebsceneMetaInfo(websceneURL, accessToken);
        const simulationWebScene = new WebScene({
            portalItem: {
                id: this.desiredWebsceneId,
                portal: this.esriConnection.getPortal()
            }
        });

        const sceneView = new SceneView({
            container: this.viewDiv,
            map: simulationWebScene
        });

        await sceneView.when();

        // Don't simplify without thinking!
        const layersToRemove = [];
        for(const layer of simulationWebScene.layers.items){
            const title = layer.title;
            if(!this._getLayersToShow().includes(title)){
                layersToRemove.push(layer);
            }
        }
        for(const layerToRemove of layersToRemove){
            simulationWebScene.layers.remove(layerToRemove);
        }

        sceneView.ui.move(["zoom", "navigation-toggle", "compass"], "top-right");

        if(this.websceneType == "result"){
            const legend = new Legend({view: sceneView});
            sceneView.ui.add(legend, "bottom-right");
        }

        /*
        const imagesForMediaLayer = await getImagesForMediaLayer(websceneURL, accessToken);
        if(imagesForMediaLayer){
            const mediaLayer = new MediaLayer({
                source: imagesForMediaLayer,
                opacity: 0.9,
                blendMode: "normal"
            });
            sceneView.map.layers.add(mediaLayer);
            await sceneView.whenLayerView(mediaLayer);
        }
        */

        const drawingLayer = new GraphicsLayer(
            { 
                graphics: [],
                elevationInfo: "on-the-ground"
            }
        );
        sceneView.map.layers.add(drawingLayer);

        this.sceneView = sceneView;
        this.drawingLayer = drawingLayer;

        await sceneView.whenLayerView(drawingLayer);
        await sceneView.when();

        this.currentWebsceneId = this.desiredWebsceneId;
        this.currentDrawingState = false; // TODO (MB): This stuff is dangerous.
    }

    _setupDrawingState(){
        if(!this.currentWebsceneId){
            return;
        }
        if(this.desiredDrawingState){
            console.log("creating sketch widget")
            const sketch = new Sketch(
                {
                    view: this.sceneView, 
                    layer: this.drawingLayer,
                    tooltipOptions: { enabled: true },
                    labelOptions: { enabled: true }
                }
            );
            this.sketch = sketch;

            sketch.viewModel.polygonSymbol = {
                type: "polygon-3d",
                symbolLayers: [
                    { 
                        type: "extrude", 
                        size: 30, 
                        material: { color: [100, 100, 100, 0.3] },
                        edges: {
                            type: "solid",
                            color: [50, 50, 50, 0.5]
                          }
                    }
                ]
            }
            sketch.viewModel.create("circle");
            sketch.on("create", async (event) => {
                if (event.state === "start") {
                    console.log("start");
                    this.drawingLayer.removeAll();
                    console.log("removed all");
                }
                if (event.state === "complete") {
                    console.log("complete");
                    const graphic = this.drawingLayer.graphics.at(0);
                    await projection.load();
                    const geometryDegree = projection.project(graphic.geometry, {wkid: 4326});
                    const extent = [
                        [geometryDegree.extent.xmin, geometryDegree.extent.ymin],
                        [geometryDegree.extent.xmax, geometryDegree.extent.ymax]
                    ];
                    this.onExtentDrawed(extent);
                    console.log("completed complete");
                }
            });
            console.log("created sketch widget");
        }
        else{
            if(this.sketch){
                console.log("destroying sketch widget");
                this.drawingLayer.removeAll();
                this.sketch.destroy();
                this.sketch = undefined;
                console.log("destroyed sketch widget");
            }
        };
        this.currentDrawingState = this.desiredDrawingState;
    }

    _getLayersToShow(){
        if(this.websceneType == "input"){
            return ["Input_Objects"];
        }
        else if(this.websceneType == "result"){
            return ["Output_Objects", "Wind_Velocity_near_Surface"];
        }
        else{
            return [];
        }
    }

}