import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.jsx'

console.log("Version: 2024-01-09 21:14")

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
