import APIConnection from './APIConnection.js';
import Coordinates from './Coordinates.js';

function getRestErrorResponse(responseJson){
    return Response.json(
        {
        status: 499, 
        title: "Error accessing ESRI REST API", 
        type: String(responseJson), 
        detail: "about:blank"
        },
        {ok: false, status: 499, statusText: 'Error accessing ESRI REST API'}
    );
}

export default class SimulationAPI {

    constructor(esriConnection){
        this.api = new APIConnection(esriConnection.apiBasePath);
        this.esriConnection = esriConnection;
    }

    async createOutputWebscene(name, inputSceneId){
        const inputUrl = this.esriConnection.getWebsceneUrl(inputSceneId) + "/data";
        let response = await fetch(
            inputUrl,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    "f": "json",
                    "token": this.esriConnection.accessToken
                })
            }
        );
        if(!response.ok){
            return getRestErrorResponse(await response.json());
        }
        const websceneJson = await response.json();

        const outputUrl = this.esriConnection.portalUrl + "/sharing/rest/content/users/" + this.esriConnection.userId + "/addItem";
        response = await fetch(
            outputUrl,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    "title": name,
                    "tags": "sim-result-scene,sim-output",
                    "type": "Web Scene",
                    "text": JSON.stringify(websceneJson),
                    "token": this.esriConnection.accessToken,
                    "f": "json",
                })
            }
        );
        if(!response.ok){
            return getRestErrorResponse(await response.json());
        }
        return response;   
    }

    async getObjectsLayerURLs(websceneItemId){
        // TODO (MB): The whole workflow in this function is not necessarily always correct. Check!
        // Also, the function fails for unclear and difficult to reproduce reasons, that's why it's
        // surrounded by a catch-all try-catch-block.
        try{
            let url = this.esriConnection.getWebsceneUrl(websceneItemId) + "/data";
            let response = await fetch(
                url + "?" + new URLSearchParams({
                    token: this.esriConnection.accessToken,
                    f: "json"
                }),
                {
                    method: "GET"
                }
            );

            if(!response.ok){
                return "";
            }
            
            let responseJson = await response.json();
            const operationalLayers = responseJson["operationalLayers"];
            
            const inputLayer = operationalLayers.find(item => {return item["title"] ==="Input_Objects";});
            const outputLayer = operationalLayers.find(item => {return item["title"] ==="Output_Objects";}); 
            
            const inputFeatureLayerURL = await getFeatureLayerToSceneLayer(inputLayer, this.esriConnection);
            let outputFeatureLayerURL = "create_new";
            if(outputLayer){
                outputFeatureLayerURL = await getFeatureLayerToSceneLayer(outputLayer, this.esriConnection);
            }
            return {"input": inputFeatureLayerURL, "output": outputFeatureLayerURL}
        }
        catch(error){
            console.error(error, error.stack);
            return undefined;
        }
    }

    // if starting the simulation failed
    async deleteWebscene(websceneId){
        const url = this.esriConnection.getWebsceneAdminUrl(websceneId) + "/delete";
        const response = await fetch(
            url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    "f": "json",
                    "token": this.esriConnection.accessToken
                })
            }
        );
        return response;
    }

    async startWindSimulation(config, extent_degree) {

        let response = await this.createOutputWebscene(config.name, config.inputScene);
        if(!response.ok){
            return response;
        }
        const responseJson = await response.json();
        const websceneId = responseJson["id"];

        // TODO (MB): In general, it's not nice that we have to do so much computation and
        // assembly of the requests...
        const oversize_factor = 1.1;
        const extent_local_uncentered = Coordinates.degree_to_cartesian(extent_degree, extent_degree[0]);
        const extent_local = [[-extent_local_uncentered[1][0] / 2, -extent_local_uncentered[1][1] / 2], [extent_local_uncentered[1][0] / 2, extent_local_uncentered[1][1] / 2]];
        const diameter_extent_circle = (extent_local[1][0] - extent_local[0][0]);
        const edge_length_wind_channel = diameter_extent_circle * Math.sqrt(2) * oversize_factor;
        const center_degree = [(extent_degree[0][0] + extent_degree[1][0]) / 2, (extent_degree[0][1] + extent_degree[1][1]) / 2];
        
        // Such that we can rotate the original extent and it always stays inside the extent with margin.
        // We need to extend it twice! (wind channel needs to be rotated inside, and terrain image needs to be rotated inside wind channel)
        const extent_with_margin_degree = Coordinates.square_around_center_degree(center_degree, edge_length_wind_channel * Math.sqrt(2) * oversize_factor);

        // TODO (MB): Plausibility check for user input? Compute those numbers automatically?
        const terrain_resolution = Number(config.terrain_resolution);
        const objects_resolution = Number(config.objects_resolution);
        const measurement_offset = Number(config.measurement_offset);

        colormap["max"] = Math.ceil(Number(config.velocity) + 1)

        const terrain_image_width = 1000;
        const foam_delta_t = 1;
        const foam_end_time = 50;
        const foam_write_interval = 10;
        
        const request_checkout = structuredClone(request_checkout_template);
        request_checkout["operation_args"]["bbox_min"] = extent_with_margin_degree[0];
        request_checkout["operation_args"]["bbox_max"] = extent_with_margin_degree[1];
        request_checkout["operation_args"]["terrain_url"] = this.esriConnection.terrainUrl;
        request_checkout["operation_args"]["terrain_resolution"] = terrain_resolution;
        request_checkout["operation_args"]["objects_resolution"] = objects_resolution;
        request_checkout["operation_args"]["measurement_offset"] = measurement_offset;
        request_checkout["operation_args"]["objects_url"] = config.inputObjectsUrl;
        request_checkout["operation_args"]["portal_root"] = this.esriConnection.portalUrl + "/sharing/rest";
        request_checkout["operation_args"]["user_name"] = this.esriConnection.userId;
        request_checkout["operation_args"]["app_id"] = this.esriConnection.appId;
        request_checkout["operation_args"]["source_portal_item"] = config.inputScene;
        request_checkout["operation_args"]["portal_item"] = websceneId;
        request_checkout["operation_args"]["simulation_name"] = config.name;
        request_checkout["operation_args"]["token"] = this.esriConnection.refreshToken;
        response = await this.api.jsonBodyRequest(
            "/jobs", request_checkout, {method: "PUT"}
        );
        if(!response.ok){
            this.deleteWebscene(websceneId);
            return response;
        }
        const job_id_checkout = await this.api.getBodyJson(response);
        
        const request_wind = structuredClone(request_wind_template);
        request_wind["depends_on_jobs"] = [job_id_checkout];
        request_wind["operation_args"]["source_job"] = job_id_checkout;
        request_wind["operation_args"]["input_scene"]["lhw"] = [edge_length_wind_channel, -1, edge_length_wind_channel];
        request_wind["operation_args"]["input_scene"]["wind"]["velocity"] = Number(config.velocity);
        request_wind["operation_args"]["input_scene"]["wind"]["meteorological_direction"] = Number(config.meteorological_direction);
        request_wind["operation_args"]["foam_block_mesh"]["cell_size"] = {"meta_command": "get", "key": "foam_block_mesh.cell_size", "job": job_id_checkout}
        request_wind["operation_args"]["foam_control"]["delta_t"] = foam_delta_t;
        request_wind["operation_args"]["foam_control"]["end_time"] = foam_end_time;
        request_wind["operation_args"]["foam_control"]["write_interval"] = foam_write_interval;
        request_wind["operation_args"]["input_scene"]["input_geometries"].push(
            {
                "meta_command": "import_list_elements",
                "file_name": job_id_checkout + ":feature_list_wind.json"
            }
        )
        response = await this.api.jsonBodyRequest(
            "/jobs", request_wind, {method: "PUT"}
        );

        if(!response.ok){
            this.deleteWebscene(websceneId);
            return response;
        }
        const job_id_wind = await this.api.getBodyJson(response);
        
        const request_sampling = structuredClone(request_sampling_template);
        request_sampling["depends_on_jobs"] = [job_id_checkout, job_id_wind];
        request_sampling["operation_args"]["source_job_geometry"] = job_id_checkout;
        request_sampling["operation_args"]["source_job_foam"] = job_id_wind;
        request_sampling["operation_args"]["bbox_min"] = extent_local[0];
        request_sampling["operation_args"]["bbox_max"] = extent_local[1];
        request_sampling["operation_args"]["sampling_geometries"].push(
            {
                "meta_command": "import_list_elements",
                "file_name": job_id_checkout + ":feature_list_sampling.json"
            }  
        )
        response = await this.api.jsonBodyRequest(
            "/jobs", request_sampling, {method: "PUT"}
        );

        if(!response.ok){
            this.deleteWebscene(websceneId);
            return response;
        }
        const job_id_sampling = await this.api.getBodyJson(response);
        
        const request_image = structuredClone(request_image_template);
        request_image["depends_on_jobs"] = [job_id_sampling];
        request_image["operation_args"]["source_job"] = job_id_sampling;

        request_image["operation_args"]["projected_images"][0]["image_width"] = terrain_image_width;
        request_image["operation_args"]["projected_images"][0]["bbox_min"] = extent_local[0];
        request_image["operation_args"]["projected_images"][0]["bbox_max"] = extent_local[1];

        request_image["operation_args"]["projected_images"][1]["image_width"] = terrain_image_width;
        request_image["operation_args"]["projected_images"][1]["bbox_min"] = extent_local[0];
        request_image["operation_args"]["projected_images"][1]["bbox_max"] = extent_local[1];
        request_image["operation_args"]["projected_images"][1]["output"]["georef_bbox_min"] = extent_degree[0];
        request_image["operation_args"]["projected_images"][1]["output"]["georef_bbox_max"] = extent_degree[1];

        request_image["operation_args"]["projected_images"][2]["image_width"] = terrain_image_width;
        request_image["operation_args"]["projected_images"][2]["bbox_min"] = extent_local[0];
        request_image["operation_args"]["projected_images"][2]["bbox_max"] = extent_local[1];
        request_image["operation_args"]["projected_images"][2]["output"]["georef_bbox_min"] = extent_degree[0];
        request_image["operation_args"]["projected_images"][2]["output"]["georef_bbox_max"] = extent_degree[1];

        request_image["operation_args"]["projected_images"][3]["image_width"] = terrain_image_width;
        request_image["operation_args"]["projected_images"][3]["bbox_min"] = extent_local[0];
        request_image["operation_args"]["projected_images"][3]["bbox_max"] = extent_local[1];
        request_image["operation_args"]["projected_images"][3]["output"]["georef_bbox_min"] = extent_degree[0];
        request_image["operation_args"]["projected_images"][3]["output"]["georef_bbox_max"] = extent_degree[1];

        request_image["operation_args"]["projected_images"][4]["image_width"] = terrain_image_width;
        request_image["operation_args"]["projected_images"][4]["bbox_min"] = extent_local[0];
        request_image["operation_args"]["projected_images"][4]["bbox_max"] = extent_local[1];
        request_image["operation_args"]["projected_images"][4]["output"]["georef_bbox_min"] = extent_degree[0];
        request_image["operation_args"]["projected_images"][4]["output"]["georef_bbox_max"] = extent_degree[1];
        response = await this.api.jsonBodyRequest(
            "/jobs", request_image, {method: "PUT"}
        );

        if(!response.ok){
            this.deleteWebscene(websceneId);
            return response;
        }
        const job_id_image = await this.api.getBodyJson(response);

        const request_gltf = structuredClone(request_gltf_template);
        request_gltf["depends_on_jobs"] = [job_id_sampling, job_id_checkout];
        request_gltf["operation_args"]["source_job"] = job_id_sampling;
        request_gltf["operation_args"]["sampling_geometries"].push(
            {
                "meta_command": "import_list_elements",
                "file_name": job_id_checkout + ":feature_list_gltf.json"
            }  
        )
        response = await this.api.jsonBodyRequest(
            "/jobs", request_gltf, {method: "PUT"}
        );

        if(!response.ok){
            this.deleteWebscene(websceneId);
            return response;
        }
        const job_id_gltf = await this.api.getBodyJson(response);

        const request_checkin = structuredClone(request_checkin_template);
        request_checkin["depends_on_jobs"] = [job_id_image, job_id_gltf];
        request_checkin["operation_args"]["source_job_terrain"] = job_id_image;
        request_checkin["operation_args"]["source_job_objects"] = job_id_gltf;
        request_checkin["operation_args"]["source_job_colormap"] = job_id_gltf;
        request_checkin["operation_args"]["bbox_min"] = extent_with_margin_degree[0];
        request_checkin["operation_args"]["bbox_max"] = extent_with_margin_degree[1];
        request_checkin["operation_args"]["terrain_image_bbox_min"] = extent_local[0];
        request_checkin["operation_args"]["terrain_image_bbox_max"] = extent_local[1];
        request_checkin["operation_args"]["objects_url"] = config.outputObjectsUrl;
        request_checkin["operation_args"]["simulation_id_field"] = config.simulationIdField;
        request_checkin["operation_args"]["simulation_id"] = websceneId;
        request_checkin["operation_args"]["object_files"].push(
            {
                "meta_command": "import_list_elements",
                "file_name": job_id_checkout + ":feature_list_checkin.json"
            }
        )
        request_checkin["operation_args"]["token"] = this.esriConnection.refreshToken;
        request_checkin["operation_args"]["portal_root"] = this.esriConnection.portalUrl + "/sharing/rest";
        request_checkin["operation_args"]["user_name"] = this.esriConnection.userId;
        request_checkin["operation_args"]["app_id"] = this.esriConnection.appId;
        request_checkin["operation_args"]["portal_item"] = websceneId;
        response = await this.api.jsonBodyRequest(
            "/jobs", request_checkin, {method: "PUT"}
        );

        if(!response.ok){
            this.deleteWebscene(websceneId);
            return response;
        }

        const request_flow = structuredClone(request_flow_template);
        request_flow["depends_on_jobs"] = [job_id_image];
        request_flow["operation_args"]["source_job_images"] = job_id_image;
        request_flow["operation_args"]["token"] = this.esriConnection.refreshToken;
        request_flow["operation_args"]["portal_root"] = this.esriConnection.portalUrl + "/sharing/rest";
        request_flow["operation_args"]["user_name"] = this.esriConnection.userId;
        request_flow["operation_args"]["app_id"] = this.esriConnection.appId;
        request_flow["operation_args"]["webscene_name"] = config.name + " (Flow)";
        request_flow["operation_args"]["georef_bbox_min"] = extent_degree[0];
        request_flow["operation_args"]["georef_bbox_max"] = extent_degree[1];
        // TODO (MB): Emergency fix!
        // AGOL/MapViewer at the moment can't handle the footprints of a 3D layer, so we just use a general OSM layer.
        // This only works if the other input objects also are from OSM footprints. 
        //request_flow["operation_args"]["objects_url"] = config.inputObjectsUrl;
        request_flow["operation_args"]["objects_url"] = "https://services-eu1.arcgis.com/zci5bUiJ8olAal7N/arcgis/rest/services/OpenStreetMap_Buildings_for_Europe/FeatureServer/0";
        response = await this.api.jsonBodyRequest(
            "/jobs", request_flow, {method: "PUT"}
        );

        const all_requests = {
            request_checkout: request_checkout,
            request_wind: request_wind,
            request_sampling: request_sampling,
            request_image: request_image,
            request_gltf: request_gltf,
            request_checkin: request_checkin,
            request_flow: request_flow
        }
        console.log("Requests dump: ")
        console.log(all_requests);

        return response;
    }

    async getErrorJson(response){
        return await this.api.getErrorJson(response);
    }
   
};

const timeouts = {
    "run": 8 * 3600,
    "keep": 2 * 24 * 3600
}

const colormap = {
    "max": 6,
    "min": 0,
    "name": "jet",
    "reversed": false,
    "type": "named",
    "bad_color": "#FFFFFF"
}

const request_checkout_template = {
    "depends_on_jobs": [],
    "operation_args": {
        "operation": "ESRICheckout",
        "bbox_min": null,
        "bbox_max": null,
        "terrain_url": null,
        "terrain_resolution": null,
        "objects_url": null,
        "portal_root": null,
        "user_name": null,
        "app_id": null,
        "token": null
    },
    "target_machine": "",
    "timeouts": timeouts
}

const request_wind_template = {
    "depends_on_jobs": [],
    "target_machine": "",
    "timeouts": timeouts,
    "operation_args": {
        "operation": "WindSimulation",
        "source_job": null,
        "foam_block_mesh": {
            "cell_size": null
        },
        "foam_control": {
            "delta_t": null,
            "end_time": null,
            "write_interval": null
        },
        "foam_fvSolution": {
            "SIMPLE_residual": 0.0001
        },
        "input_scene": {
            "center": [0, -1, 0],
            "lhw": null,
            "wind": {
                "abl_z0": 0.1,
                "abl_zref": 10,
                "meteorological_direction": null,
                "type": "abl",
                "velocity": null
            },
            "input_geometries": []
        }
    }
}

const request_sampling_template = {
    "depends_on_jobs": [],
    "target_machine": "",
    "timeouts": timeouts,
    "operation_args": {
        "operation": "Sampling",
        "source_job_geometry": null,
        "source_job_foam": null,
        "bbox_min": null,
        "bbox_max": null,
        "sampling_geometries": []
    }
}

const request_image_template = {
    "depends_on_jobs": [],
    "target_machine": "",
    "timeouts": timeouts,
    "operation_args": {
        "operation": "ProjectedImage",
        "source_job": null,
        "projected_images": [
            {
                "sampling_name": "terrain",
                "projection_plane": "xz",
                "bbox_min": null,
                "bbox_max": null,
                "image_width": null,
                "output": {
                    "type": "colormapped_png",
                    "output_file_name": "terrain.png",
                    "colormap_index": 0
                }
            },
            {
                "sampling_name": "terrain",
                "projection_plane": "xz",
                "bbox_min": null,
                "bbox_max": null,
                "image_width": null,
                "output": {
                    "type": "geotiff",
                    "output_file_name": "terrain_xyz.tif",
                    "data_type": "Float32",
                    "band_map": "all",
                    "georef_bbox_min": null,
                    "georef_bbox_max": null
                }
            },
            {
                "sampling_name": "terrain",
                "projection_plane": "xz",
                "bbox_min": null,
                "bbox_max": null,
                "image_width": null,
                "output": {
                    "type": "geotiff",
                    "output_file_name": "terrain_uv.tif",
                    "data_type": "Float32",
                    "band_map": "xz_to_uv",
                    "georef_bbox_min": null,
                    "georef_bbox_max": null
                }
            },
            {
                "sampling_name": "terrain",
                "projection_plane": "xz",
                "bbox_min": null,
                "bbox_max": null,
                "image_width": null,
                "output": {
                    "type": "netcdf",
                    "output_file_name": "terrain_uv.nc",
                    "variable_map": "uv",
                    "georef_bbox_min": null,
                    "georef_bbox_max": null
                }
            },
            {
                "sampling_name": "terrain",
                "projection_plane": "xz",
                "bbox_min": null,
                "bbox_max": null,
                "image_width": null,
                "output": {
                    "type": "netcdf",
                    "output_file_name": "terrain_magdir.nc",
                    "variable_map": "magdir",
                    "georef_bbox_min": null,
                    "georef_bbox_max": null
                }
            }
        ],
        "colormaps": [colormap]
    }
}

const request_gltf_template = {
    "depends_on_jobs": [],
    "target_machine": "",
    "timeouts": timeouts,
    "operation_args": {
        "operation": "GLTFCreation",
        "source_job": null,
        "sampling_geometries": [],
        "colormaps": [colormap],
        "colormap_legends": [
            {
                "colormap_index": 0,
                "file_name": "colormap.png", // TODO (MB): At the moment, the service expects that this is always "colormap.png"
                "n_ticks": 4,
                "text": "Wind Velocity [m/s]",
                "tick_format": "{:.1f}",
                "type": "bar"
            },
            {
                "colormap_index": 0,
                "file_name": "rasterStretchRenderer.json",
                "parts": 6,
                "type": "esriRasterStretch"
            }
        ]
    }
}

const request_checkin_template = {
    "depends_on_jobs": [],
    "operation_args": {
        "operation": "ESRICheckin",
        "source_job_terrain": null,
        "source_job_objects": null,
        "source_job_colormap": null, // TODO (MB): At the moment, we expect that this contains a colormap.png
        "bbox_min": null,
        "bbox_max": null,
        "terrain_file_name": "terrain.png",
        "terrain_file_name_magdir": "terrain_magdir.nc",
        "terrain_image_bbox_min": null,
        "terrain_image_bbox_max": null,
        "objects_url": null,
        "object_files": [],
        "portal_item": null,
        "portal_root": null,
        "user_name": null,
        "app_id": null,
        "token": null
    },
    "target_machine": "",
    "timeouts": timeouts
}

const request_flow_template = {
    "depends_on_jobs": [],
    "operation_args": {
        "operation": "ESRICreateFlow",
        "source_job_images": null, 
        "portal_root": null,
        "app_id": null,
        "user_name": null,
        "token": null,
        "webscene_name": null,
        "terrain_uv_file_name": "terrain_uv.nc",
        "georef_bbox_min": null,
        "georef_bbox_max": null,
        "objects_url": null
    },
    "target_machine": "",
    "timeouts": timeouts
}

async function getFeatureLayerToSceneLayer(sceneLayer, esriConnection){
    const sceneLayerItemId = sceneLayer["itemId"];

    const url = esriConnection.portalUrl + "/sharing/rest/content/items/" + sceneLayerItemId + "/relatedItems";
    const response = await fetch(
        url + "?" + new URLSearchParams({
            token: esriConnection.accessToken,
            f: "json"
        }),
        {
            method: "GET"
        }
    );

    if(!response.ok){
        return "";
    }

    const responseJson = await response.json();
    const relatedItems = responseJson["relatedItems"];
    const featureService = relatedItems.find(item => {return item["type"] === "Feature Service"});
    if(!featureService){
        return "";
    }
    const featureLayerUrl = featureService["url"] + "/0";
    return featureLayerUrl;        
}