import * as React from 'react';
import {Box, Paper, IconButton, Typography} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

export default function UIContainer(props) {

    const height = props.height?props.height:"80%";

    return (
        <>
            <Paper sx={{
                maxWidth: "600px", width: "100%",
                marginTop: "30px",
                height: height,
                zIndex: 1000,
                padding: "1rem",
                display: "flex", flexDirection: "column"
            }}>
                <Box sx={{flex: "0 0 content", display: "flex", justifyContent: "space-between", padding: "1rem"}}>
                    <Typography  variant="h5" sx={{display: "inline"}}>{props.title}</Typography>
                    <IconButton
                        onClick={props.onClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box sx={{flex: "1 1 auto", overflow: "auto", height: "100%"}}>
                    {props.children}
                </Box>
            </Paper>
        </>
    );
}