import {useContext} from "react";
import {Box, Button, ButtonGroup , Modal, Paper, Stack, Typography}  from '@mui/material';

import {ESRIContext} from './../general/ESRIConnection.js';


export default function App(props) {

    const esriConnection = useContext(ESRIContext);
    
    return (
        <Modal disableAutoFocus={true} open>
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: "400px"
            }}>
                <Paper elevation={4} sx={{
                    width: "100%", height: "100%", padding: "1rem",
                    borderStyle: "solid", borderWidth: "1px", borderColor: theme => theme.palette.primary.main 
                }}>
                    <Box>
                        <Typography variant="h6">Login</Typography>
                        <Typography variant="body1" sx={{marginTop: "1em"}}>Please select the portal to use.</Typography>
                    </Box>
                    <Box sx={{display: "flex", justifyContent: "flex-end", alignItems: "flex-end", marginTop: "5rem"}}>
                        <Stack>
                        <Button variant="contained" onClick={()=>esriConnection.signIn("https://www.arcgis.com")} sx={{marginTop: "1rem"}}>www.arcgis.com</Button>
                        <Button variant="contained" onClick={()=>esriConnection.signIn("https://zrh.mapsdevext.arcgis.com")} sx={{marginTop: "1rem"}}>zrh.mapsdevext.arcgis.com</Button>
                        </Stack>
                    </Box>
                </Paper>
            </Box>
        </Modal>
    );
}
